import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  Image,
  BannerLinks,
  Breadcrumbs,
  BlogSlider,
  Appointment
} from '../../components'

import BannerImage from '../../assets/projektuebersicht.jpg'
import boxes from '../../components/BannerLinks/DefaultBox'
import cards from '../../components/BlogSlider/DefaultCards'

import { generateBreadcrumbsLinks } from '../../utils/index'

import dropbox from '../../assets/dropbox.jpg'
import Georg from '../../assets/georg_neu.png'

const SplitScreen = () => {
  const links = generateBreadcrumbsLinks('/blog/split-screens', 'Split Screens')
  return (
    <Layout>
      <Helmet
        title="Split Screens - Dualität mit Style Faktor"
        meta={[
          {
            name: 'description',
            content:
              'Wenn der Inhalt eines Bildschirms in zwei Teile geteilt wird, die unabhängig voneinander funktionieren, nennt man diese Technik Split-Screen.'
          },
          {
            name: 'keywords',
            content:
              'Split Screens holzweg, Blog holzweg, Innsbruck, holzweg, Blog, Split Screens, geteilter Bildschirm, Trend Webseite, Website Trend 2019'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/splitscreens-bc1232faca8e5ddf4d79d04306bbd664.jpg'
          },
          {
            property: 'og:description',
            content:
              'Wenn der Inhalt eines Bildschirms in zwei Teile geteilt wird, die unabhängig voneinander funktionieren, nennt man diese Technik Split-Screen.'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/splitscreens-bc1232faca8e5ddf4d79d04306bbd664.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            2-geteilter
            <br />
            Bildschirm
          </span>
        }
      />
      <Breadcrumbs
        backLinkAlias=' '
        links={links}
      />
      <TextBox
        title={
          <span>Split Screens - <br/>Dualität mit Style Faktor</span>
        }
        text=
          {
            <span>
              <p>Wenn der Inhalt eines Bildschirms in zwei Teile geteilt wird, die unabhängig voneinander funktionieren, nennt man diese Technik "Split-Screen". Die Geschichte der zweigeteilten Bildschirme ist so lang wie die Geschichte des Films und kommt schon in frühen Stummfilmen vor. Bis heute hat sich diese Technik in Film und TV bewährt, neu ist sie 2018 als Trend im Web dazugekommen!
              </p>
              <h3>Wann funktionieren Split-Screens, wann nicht?</h3>
              <p>Split-Screens sind gut, wenn man z.B. eine Art Übersicht mit Titel auf einer Seite hat und den eigentlichen Content rechts zum Scrollen. Ein Beispiel könnt Ihr Euch bei den <a href="https://www.dropbox.com/guide/admin/set-up/add-tiered-admins" target="_blank">Dropbox Guidenlines</a> ansehen:
              </p>
            </span>
          }
      />
      <Image
        image={dropbox}
        title=""
      />
      <TextBox
        title=""
        text=
          {
            <span>
              <p>Split-Screens funktionieren allerdings nur bei einfachem Content. Wird der Content komplexer - sowohl inhaltlich wie auch im Design - sollte auf ein traditionelleres Layout ausgewichen werden.
              </p>
              <h3>Entscheidungskriterien:</h3>
              <ul>
                <li>Ist der eigentliche Inhalt simpel? Text mit Bild?</li>
                <li>Kann genügend <a href="https://de.wikipedia.org/wiki/Typografischer_Wei%C3%9Fraum" target="_blank" alt="Wikipedia Link">Weißraum (Negativraum)</a> verwendet werden?</li>
                <li>Wird der User mit dem neuartigen Scrollverhalten und Layout nicht überfordert (Barrierefreiheit)?</li>
              </ul>
              <p>Sollten die Kriterien für Euren Webseiten-Inhalt zutreffen, könnte ein Split-Screen-Layout für Eure User interessant sein! Sollen wir uns dazu mal austauschen?</p>
            </span>
          }
      />
      <Appointment image={Georg} />
      <BlogSlider cards={cards} singleRow={true} title="Weitere Blogbeiträge:" />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default SplitScreen
